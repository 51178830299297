$white : #fff;

.container{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .about{
        font-family: 'Poiret One', cursive;
        font-size: calc(31px + (45 - 31) * ((100vw - 1200px) / (1920 - 1200)));
        letter-spacing: 0.7px;
        line-height: 1.3;
        max-width: 80%;
        color: $white;
    }

    .workButton{
        color: $white;
        z-index: 1;
        font-family: 'Poiret One', cursive;
        text-decoration: none;
        padding: 15px 24px;
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-top: 30px;
        border: 1px solid #fff;
        transition: all .8s ease;

        &:hover{
            background: #fff;
            color: #000
        }
    }
}
