.bg_parts{
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;

    &__chunk{
        width: 25%;
        height: 100%;
        border-left: 1px solid transparentize(#fff, $amount: 0.85);
        background: #000;
        transform-origin: 0;

        &:first-child{
            border-left: 0;
        }
    }
}